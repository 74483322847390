import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import { IMAGES } from "Shared/Images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getMainBanners } from "Redux/Actions/common";

const PromotionSlider = () => {
  const dispatch = useDispatch();

  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    loop: false,
    // speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 2000,
    centerPadding: "0px",
    // variableWidth:true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          slidesToShow: 1,
          swipeToSlide: true,
          draggable: true,
        },
      },
    ],
  });

  const handleBannerClick = (data) => {
    window.open(data.destinationURL, "_blank")
  }

  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);

  const fetchBanners = () => {
    dispatch(
      getMainBanners({}, (response) => {

        if (response?.mainBanners?.length == 1) {
          setSettings((prev) => ({ ...prev, centerMode: false, infinite: false }));
        }
        if (response?.mainBanners?.length == 2) {
          setData([response?.mainBanners[0], response?.mainBanners[1], response?.mainBanners[0], response?.mainBanners[1]]);
        }
        else if (response?.mainBanners?.length == 3) {
          setData([response?.mainBanners[0], response?.mainBanners[1], response?.mainBanners[2], response?.mainBanners[1]]);
        } else {
          setData(response?.mainBanners || []);
        }
        setTotalCount(response.totalCount);
      })
    );
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <>
      <div className="MainSlider">
        <div className="container">
          <div className="slick_Row py-md-5 mb-md-0 mb-5 py-4">

            {data?.length ? (
              <Slider {...settings} className="rev_slider">

                {data?.map((item, index) => <div key={index + "slider"}>
                  <div className="slider" onClick={() => handleBannerClick(item)}>
                    <div className="slider_block">
                      <figure className="mb-0 slider_img ">
                        <img src={item?.popupImage} className="img-fluid" alt="card-image"/>
                      </figure>
                    </div>
                  </div>
                </div>
                )}
              </Slider>
            ) : ""}

            {/* {totalCount > 10 ?
            <div className="text-center common-btn mt-5">
              <Button
                className="btn btn-lg btn-red"
                onClick={() => {
                  history.push(ROUTES.PROMOTION);
                }}
              >
                {t("see-more-promotions")}
              </Button>
            </div> : ""} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionSlider;

import React from 'react'
import Button from '../Button'
import CustomModal from '../CustomModal'
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useSelector } from 'react-redux';

const WelcomeModal = ({ open, setOpen = () => { } }) => {
  const { t } = useTranslation();
  const content = useSelector((state) => state?.cms?.welcomePopup || {});

  return (
    <CustomModal isOpen={open} big={true} handleToggle={() => setOpen(!open)}>
      <div className="welcome_content text-black">
        <div className='cms_content' dangerouslySetInnerHTML={{ __html: content?.innerHtml || "" }}></div>
        {/* <div className="py-3">
          <div className="mt-3">
            <video
              src="https://media.istockphoto.com/id/1202294957/video/dealer-woman-shuffles-the-poker-cards-and-performing-trick-with-cards-shot-on-red-epic-cinema.mp4?s=mp4-640x640-is&k=20&c=PPCkxZ8VIQJ02jfH7Zzcfwd_kkrqaoPAXcVt6cqQenY="
              alt=""
              className='img-fluid'
              controls
              controlsList="nodownload nofullscreen noremoteplayback noplaybackspeed"
            />
          </div>
        </div> */}
        <div className="mt-4 text-center text-center">
          <Button className="btn btn-lg btn-primary" onClick={() => setOpen(false)}>
            {t("okay")}
          </Button>
        </div>
      </div>
    </CustomModal>
  )
}

export default WelcomeModal
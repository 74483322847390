import React from "react";
import Categories from "./Components/Categories";
import PromotionSlider from "./Components/PromotionSlider";
import './style.scss';
import Jackpot from "./Components/Jackpot";
import { GameProviders } from "Components";
const Home = () => {
	return (
		<div className="Home_page">
			<div className="main_content">
				<PromotionSlider />
				<Jackpot />
				<div className="categories_sec pattern_bg">
					<Categories />
					<GameProviders />
				</div>
			</div>

		</div>
	);
};

export default Home;
